import { processRequest } from "./Network";
import axios, { CancelTokenSource } from "axios";
let baseURLType = window.BaseUrl;
if (localStorage.getItem("x-auth-token")) axios.defaults.headers = { "x-auth-token": localStorage.getItem("x-auth-token") };


export function getErrorResponse(error) {
  console.log(error, "error error    error ");
  let customResponse = [];
  try {
    if (error.data?.masssage == "timeout exceeded") {
      customResponse.success = false;
      customResponse.status_code = { key: "failed", value: -1, name: "timeout" };
      customResponse.user_message = "Timeout Exceeded";
    } else if (error && error?.toJSON().message === "Network Error") {
      customResponse.success = false;
      customResponse.status_code = { key: "failed", value: -1, name: "network" };
      customResponse.user_message = "Internet problem";
    } else {
      customResponse = error?.response.data;
      !!error && console.error(`FAILED API = ${error.response.config.url} | Error Code = ${customResponse.status_code?.value} | System Message = ${customResponse.system_message}`);
      !!!error && console.log("FAILED API with undefined error");
    }
  } catch (e) {
    console.log(e, "error catch");
    customResponse.success = false;
    customResponse.status_code = { key: "failed", value: -1, name: "network" };
    customResponse.user_message = "Internet problem";
  }
  return customResponse;
}
export const getLabReportsAPI = async (patientId, page, rowsPerPage, cancelToken) => {
  const dataToSend={
    patientId:parseInt(patientId)
  }
  const url = `document/search?pageSize=${rowsPerPage}`;
  const request = { type: "POST", urlString: url, params:dataToSend };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const addLabDocumentAPI = async (data, cancelToken) => {
  const url = `document`;
  const request = { type: "POST", urlString: url, params:data };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const verifyUploadDocumentAPI = async (id, cancelToken) => {
  const url = `document/${id}/verify`;
  const request = { type: "PUT", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
export const getSingleDocumentAPI = async (id, cancelToken) => {
  const url = `document/${id}`;
  const request = { type: "GET", urlString: url };
  try {
    const response = await processRequest(request, cancelToken);
    return response.data;
  } catch (error) {
    return getErrorResponse(error);
  }
};
