import React, { useState, useEffect, useRef } from 'react';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, Form, FormGroup, Label, Input, Button, FormFeedback
} from 'reactstrap';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { getTestListAPI } from './../../../api/network/Api';
import { addLabDocumentAPI, verifyUploadDocumentAPI } from './../../../api/networkDocumentModule/Api';
import PageTitle from '../../../Layout/AppMain/PageTitle';
import axios from "axios";
import { toast, Bounce } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import Loader from '../../../api/helperComponents/Loader';
import BreadCrumb from '../../../api/helperComponents/BreadCrumb';
import userUtil from '../../../utils/UserUtils';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
const CenterAdd = () => {
    const history = useHistory()
    const params = useParams()
    const docImage = useRef();
    const cancelTokenSource = axios.CancelToken.source();
    const [testCategoriesData, setTestCategoriesData] = useState([]);
    const [selectTestValue, setSelectTestValue] = useState("");
    const [selectTestValueError, setSelectTestValueError] = useState(false);
    const [fileInfo, setFileInfo] = useState({
        fileSize: null,
        fileType: '',
        fileName: ''
    });
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [centerId, setCenterId] = useState(JSON.parse(localStorage.getItem('userDetail'))?.center_id);
    const [permissions, setPermissions] = useState(JSON.parse(localStorage.getItem('userPermissions')));
    const [patientNameID, setPatientNameID] = useState(localStorage.getItem('patientNameID'));
    useEffect(() => {
        getAllTestTypes()
    }, []);


    const getAllTestTypes = async () => {
        setIsLoader(true)
        const response = await getTestListAPI(cancelTokenSource.token)
        setIsLoader(false)
        if (response.success) {
            setTestCategoriesData(response?.data)
        } else {

        }
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFile(file)
            console.log(file, 'file')
            setFileInfo({
                fileSize: file.size,
                fileType: file.type,
                fileName: file.name,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (selectTestValue === "") {
            setSelectTestValueError(true);
            return;
        } else {
            setSelectTestValueError(false);
        }
    
        if (!file) {
            setFileError(true);
            return;
        } else {
            setFileError(false);
        }
    
        console.log('handleSubmit called');
        console.log(fileInfo, 'fileInfo');
    
        const dataToSend = {
            patientId: parseInt(params?.patient_id),
            documentTypeId: selectTestValue,
            fileName: fileInfo?.fileName,
            fileSize: fileInfo?.fileSize.toString(),
            contentType: fileInfo?.fileType,
        };
    
        console.log(dataToSend, 'dataToSend');
    
        try {
            setIsLoader(true);
            const response = await addLabDocumentAPI(dataToSend, cancelTokenSource.token);
            setIsLoader(false);
    
            if (response?.signUrl) {
                const token = axios.defaults.headers["x-auth-token"];
                delete axios.defaults.headers["x-auth-token"];
    
                try {
                    await axios.put(response.signUrl, file, {
                        headers: {
                            "Content-Type": fileInfo?.fileType,
                        },
                    });
                    axios.defaults.headers["x-auth-token"] = token;
    
                    const res = await verifyUploadDocumentAPI(response?.id, cancelTokenSource.token);
                    setIsLoader(false);
    
                    if (res.status == "UPLOADED") {
                        toast("Report Uploaded Successfully!", {
                            transition: Bounce,
                            closeButton: true,
                            autoClose: 5000,
                            position: 'bottom-center',
                            type: 'success'
                        });
                        history.push(`patient/lab-test/list/${params?.patient_id}`)
                        // Handle successful verification
                    } else {
                        toast("Error on Uploading!", {
                            transition: Bounce,
                            closeButton: true,
                            autoClose: 5000,
                            position: 'bottom-center',
                            type: 'error'
                        });
                        // Handle verification failure
                    }
                } catch (err) {
                    toast("Error on Uploading!", {
                        transition: Bounce,
                        closeButton: true,
                        autoClose: 5000,
                        position: 'bottom-center',
                        type: 'error'
                    });
                    axios.defaults.headers["x-auth-token"] = token;
                    console.error("Image Upload Failed Response", err);
                    // setErrorMessage(err.response?.data?.message || "File upload failed");
                    setIsLoader(false);
                }
            } else {
                console.error("No signed URL returned from server");
                // setErrorMessage("Failed to get signed URL");
            }
        } catch (error) {
            toast("Error on Uploading Successfully!", {
                transition: Bounce,
                closeButton: true,
                autoClose: 5000,
                position: 'bottom-center',
                type: 'error'
            });
            console.error("Error in addLabDocumentAPI:", error);
            setIsLoader(false);
            // setErrorMessage(error.response?.data?.message || "Failed to add lab document");
        }
    };
    
    

    return (
        <div>
            {isLoader && (<Loader />)}
            <Row className="justify-content-center">
                <Col md="10" >
                    <BreadCrumb items={[
                        { label: "Dashboard", url: "/" },
                        { label: "Patients", url: "/patients" },
                        { label: patientNameID, url: `/patient/dashboard/${params?.patient_id}` },
                        { label: params?.patient_id ? " Add Lab Tests" : " Add Lab Tests" }
                    ]} />
                    <Card className="main-card mb-3">
                        <CardBody>
                            <CardTitle className="flex-space-between">{params?.patient_id ? "Add" : "Add"}{" "} Lab Test</CardTitle>
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <Col md="12" >
                                        <FormGroup>
                                            <Label for="f_name">Test Type<span style={{ color: 'red' }}>*</span></Label>
                                            <select className="form-control" defaultValue={selectTestValue} onChange={(e) => setSelectTestValue(e.target.value)}>
                                                <option value="">Select Test Type</option>
                                                {testCategoriesData && testCategoriesData.map((item, index) => (
                                                    <option value={item?.name}>{item?.name}</option>
                                                ))}
                                            </select>
                                            {selectTestValueError && (
                                                <span className="field-error">Select Test Type to Continue</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md="12" >
                                        <FormGroup>
                                            <Label for="f_name">File<span style={{ color: 'red' }}>*</span></Label>
                                            <Input ref={docImage} type="file" autoComplete="off" name="file" id="file" onChange={(e) => handleFileChange(e)} />
                                            {fileError && (
                                                <span className="field-error">Select File to Continue</span>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default CenterAdd;
